import React, { useEffect, useContext, useState, useRef } from "react";
import { Redirect } from 'react-router-dom';

import { Row, Col, Radio, Spin, Modal, Button, Form, Input } from "antd";
import { Auth } from "../../utils/AuthContext";
import { withRouter } from "react-router";
import Firebase from '../../utils/firebaseConfig';
import Live from '../conferencias/Live';
import { Preguntas } from '../conferencias/Preguntas';
import { Estadisticas } from '../conferencias/Estadisticas';
import Crucigrama from '../paginas/Crucigrama';
import '../../assets/css/preguntas_popup.css';

import HeaderIngenieriaVehicular from '../master/header/HeaderIngenieriaVehicular';
import FooterIngenieriaVehicular from '../master/footer/FooterIngenieriaVehicular';

import img_aprobado from '../../assets/images/asamblea2022/antp_BotonAprobado.png';
import img_aprobado_hover from '../../assets/images/asamblea2022/antp_BotonAprobado_scroll.png';
import img_noaprobado from '../../assets/images/asamblea2022/antp_BotonNOAprobado.png';
import img_noaprobado_hover from '../../assets/images/asamblea2022/antp_BotonNOAprobado_scroll.png';
import img_votar from '../../assets/images/asamblea2022/antp_BotonVOTAR.png';
import qr_informe from '../../assets/images/asamblea2022/qrcode_459636.png';
import { CheckOutlined } from "@ant-design/icons";

const nombre_evento = '2022';
const app = new Firebase();
let respuesta_elegida = null;
let comentario_escrito = null;

let key = '';
const EventoEnVivo = ({ history }) => {

    const { usuario, datos } = useContext(Auth);
    const [preguntas_ponente, setPreguntasPonente] = useState([]);
    const [etapa, setEtapa] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [preguntas, setPreguntas] = useState([]);
    const [estadisticas, setEstadisticas] = useState([]);
    const [mostrar_pregunta, setMostrarPregunta] = useState(false);
    const [preguntas_pop_up, setPreguntasPopUp] = useState([]);
    const [pregunta_pop_up_activa, setPreguntaPopUpActiva] = useState(0);
    
    const [respuesta_usuario, setRespuestaUsuario] = useState(null);
    const [inputValue, setInputValue] = useState(null);
    const [aprobado,setAprobado] = useState(false);
    const [no_aprobado,setNoAprobado] = useState(false);
    const [username, userInput] = useInput({ type: "text", apro:no_aprobado === true ? true : false });

    const [datos_crusigrama, setDatosCrusigrama] = useState(null);
    const [key_crusigrama, setKeyCrusigrama] = useState(undefined);
    const [respuestas, setRespuestas] = useState([]);
    const [dia, setDia] = useState(1);
    const [ver_video, setVerVideo] = useState(1);
    const [mostrar_encuesta, setMostrarEncuesta] = useState(false);

    const ref_respuesta_popup = useRef(null);

    const [enviada, setEnviada] = useState(false);

    const [count, setCount] = useState(0);
    const [intervalId, setIntervalId] = useState(0);
    const [mostrar_comentario,setMostrarComentario] = useState(false);


    const [ver_envivo, setVerEnvivo] = useState (false);
    const [modalidad, setModalidad] = useState ('');

    let comentario_enviar = '';

    const handleClick = () => {
        console.log('interval',intervalId);
        clearInterval(intervalId);
            setIntervalId(0);
            
        setCount(0);
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(0);
            return;
        }

        const newIntervalId = setInterval(() => {
            setCount(prevCount => prevCount + 1);
        }, 1000);
        setIntervalId(newIntervalId);
    };

    useEffect(() => {
        console.log('mostrar_pregunta',mostrar_pregunta);
        if(mostrar_pregunta != true){
            
        }
        clearInterval(intervalId);
            setIntervalId(0);
    },[mostrar_pregunta]);

    const siguienteEtapa = (usuario_respuestas) => {

        key = usuario_respuestas.key;

        switch (etapa) {
            case 0:
                let existe_respuestas = usuario_respuestas.respuestas;
                app.obtener_objetos(nombre_evento + '/preguntas-vivo', false, (datos) => {

                    setPreguntas(datos);
                    app.obtener_objetos(nombre_evento + '/usuarios-encuesta', true, (datos) => {

                        setEstadisticas(datos);
                        setCargando(false);


                        if (typeof existe_respuestas !== 'undefined') {

                            setEtapa(2);
                        } else {

                            setEtapa(1);
                        }


                    });
                })

                break;
            case 1:

                app.obtener_objetos(nombre_evento + '/usuarios-encuesta', true, (datos) => {

                    setEstadisticas(datos);
                    setCargando(false);
                    setEtapa(2);
                });
                break;
            default:
                break;
        }
    }

    const guardarRespuestas = (respuestas) => {

        const actualizar_usuario = {
            correo: datos.correo,
            respondio: true,
            respuestas: respuestas
        }
        setCargando(true);
        app.escribir(nombre_evento + '/usuarios-encuesta', key, actualizar_usuario, siguienteEtapa);

    }

    



    useEffect(() => {

        if (usuario === null) {
            window.location.href = "/";

        } else {
            if (datos.correo) {

                if (Object.entries(datos).length !== 0) {
                    app.obtener_objeto(nombre_evento + '/usuarios-encuesta', 'correo', datos.correo, siguienteEtapa);
                }
                app.obtener_objetos(nombre_evento + '/preguntas-publico', true, (data) => {

                    setPreguntasPonente(data);
                });

                app.obtener_objeto(nombre_evento + '/preguntas-popup-publico', 'correo', datos.correo, (data) => {
                    setRespuestaUsuario(data);
                });
                app.obtener_objeto(nombre_evento + '/comentarios', 'correo', datos.correo, (data) => {
                    setInputValue(data);
                });

                app.obtener_objeto(nombre_evento + '/crusigrama', 'correo', datos.correo, (data) => {
                    setKeyCrusigrama(data["key"]);
                    delete data["key"];
                    delete data["correo"];
                    setDatosCrusigrama(Object.values(data));
                });
            }
            if(datos.modalidad === 'virtual'){
                //aqui
                setVerEnvivo(true);
                setModalidad('virtual');
                console.log('es virtual')
            }else{
                setModalidad('presencial');
                setVerEnvivo(false);
                console.log('es presencial')
            }

        }

    }, [history, usuario, datos]);

   

    useEffect(() => {
        app.obtener_objetos(nombre_evento + '/preguntas-popup', false, (data) => {
            setPreguntasPopUp(data);
        });
        setEnviada(false);
    }, []);

    useEffect(() => {
        app.obtener_objetos(nombre_evento + '/configuracion', true, (data) => {

            setDia(data[0].dia);
            setVerVideo(data[0].ver_video);
            setMostrarEncuesta(data[0].encuesta);
            app.obtener_objetos(nombre_evento + `/preguntas-publico1`, true, (data) => {
                setPreguntasPonente(data);
            });

            app.obtener_objetos(nombre_evento + `/respuestas-admin1`, true, (data) => {
                setRespuestas(data);
            });

            if (data[0].pregunta_popup !== 0) {

                setPreguntaPopUpActiva(data[0].pregunta_popup);
                if (usuario) {

                    if (respuesta_usuario) {
                        if (respuesta_usuario['respuesta' + data[0].pregunta_popup] !== undefined) {
                            setMostrarPregunta(false);
                            setMostrarComentario(false);
                            handleClick();
                        }else if(respuesta_usuario['respuesta' + data[0].pregunta_popup] === "No aprobado"){
                            setMostrarPregunta(true);
                            setMostrarComentario(true);
                        } else {
                            setMostrarPregunta(true);
                        }
                    } else {
                        setMostrarPregunta(false);
                        setMostrarComentario(false);
                        handleClick();
                    }

                } else {
                    setMostrarPregunta(false);
                    setMostrarComentario(false);
                    handleClick();
                }
            } else {
                setPreguntaPopUpActiva(data[0].pregunta_popup);
                setMostrarPregunta(false);
                setMostrarComentario(false);
                handleClick();
            }

        });



    }, [usuario, respuesta_usuario]);

    
    function useInput({ type,apro }) {
        const [value, setValue] = useState("");    
        const input = <input value={value} onChange={e => setValue(e.target.value)} type={type} style={{width:'100%'}} required/>;
        return [value, input];
    }
    

    const elegirRespuesta = (respuesta) => {
        respuesta_elegida = respuesta;
    }
    console.log('input',username );
    comentario_escrito = username;

    const enviarRespuestaPopUp = () => {
        if (respuesta_elegida !== null) {
            let nuevo_respuesta_usuario = Object.assign({}, respuesta_usuario);
            
            nuevo_respuesta_usuario['respuesta' + pregunta_pop_up_activa] = respuesta_elegida.target.value;
            
            app.escribir(nombre_evento + '/preguntas-popup-publico', respuesta_usuario.key, nuevo_respuesta_usuario, (data) => {
                setRespuestaUsuario(data);
                setMostrarPregunta(false);
                comentario_escrito = '';
                setAprobado(false);
                setNoAprobado(false);
                setEnviada(true);
            });
            let comentario = Object.assign({}, inputValue);
            comentario['comentario' + pregunta_pop_up_activa] = comentario_escrito;
            //app.escribir(nombre_evento + '/preguntas-popup-publico', respuesta_usuario.key, comentario, userInput);
            app.escribir(nombre_evento + '/comentarios', inputValue.key, comentario, (data) => {
                setInputValue(data);
                comentario_escrito = '';
                respuesta_elegida = null;
                setAprobado(false);
                setNoAprobado(false);
                setEnviada(true);
            });
        } else {

        }
        
    }

    
    return (
        <>
            <>
                <HeaderIngenieriaVehicular />

                <div className="cuerpo_vehiculos">
                    <Row justify="" align="">
                        <Col xs={{span:20, offset:2}} lg={{span:8, offset:8}}>
                            <br />
                            {modalidad === 'presencial' &&
                                <>
                                {ver_envivo ?
                                    <p onClick={()=>setVerEnvivo(!ver_envivo)} className="desactivar_envivo">Ocultar transmisión</p>
                                :
                                <p onClick={()=>setVerEnvivo(!ver_envivo)} className="activar_envivo">Ver transmisión</p>}
                                </>
                            }
                        </Col>
                        {ver_envivo &&
                        <Col span={24}>
                        <Live
                            url="https://www.youtube.com/embed/l86I8jeJ32U?rel=0&playsinline=1&autoplay=1"
                            urlyt={null}
                            urlfb={null}
                            urltw={null}
                            webinar={nombre_evento} comentarios={preguntas_ponente} dia={dia} ver_video={ver_video} respuestas={respuestas} />
                        <br />
                    </Col>}
                    </Row>
                    <Row style={{ display: 'none' }}>
                        <Col xs={{ span: 24, offset: 2 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }} className="contenedor_graficas_">
                            {usuario &&
                                <Row style={{ display: 'non' }}>
                                    <Col span={24}>
                                        <Spin tip="Cargando..." spinning={cargando}>
                                            {mostrar_encuesta &&
                                                (etapa === 1 ?
                                                    (
                                                        <Col span={24} offset={0} style={{ display: 'non' }}>
                                                            <Preguntas preguntas={preguntas} onResponse={guardarRespuestas} />
                                                        </Col>
                                                    )
                                                    :
                                                    (
                                                        <Col span={24} style={{ display: 'non' }}>
                                                            <Estadisticas estadisticas={estadisticas} preguntas={preguntas} />
                                                        </Col>
                                                    )
                                                )
                                            }
                                        </Spin>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                    <Row justify="center" align="middle">
                        <Col xs={20} md={14} align="center">
                            <p className="sistema_votacion"><strong>Votación de acuerdos</strong>
                            <br />Asamblea General Ordinaria ANTP</p>
                            {mostrar_pregunta &&
                                <>
                                    {preguntas_pop_up.filter(pregunta => {
                                        if (pregunta.num_pregunta === pregunta_pop_up_activa) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }).map(pregunta => {
                                        return (
                                            <p className="texto_pregunta_popup">{pregunta.pregunta}</p>
                                        );
                                    })}
                                    {
                                        preguntas_pop_up.filter(pregunta => {
                                            if (pregunta.num_pregunta === pregunta_pop_up_activa) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }).map(pregunta => {
                                            let respuestas = Object.values(pregunta.respuestas).map(respuesta => {
                                                if(respuesta.respuesta === 'Aprobado'){
                                                    return (
                                                        <>
                                                        
                                                        <Radio className="texto_respuesta_popup" value={respuesta.respuesta}>
                                                            <img  onClick={()=>{setAprobado(true); setNoAprobado(false)}} src={aprobado ? img_aprobado_hover : img_aprobado} class="img_votar"/>
                                                    </Radio>
                                                        </>
                                                    );
                                                }else{
                                                    return (
                                                        <>
                                                    <Radio className="texto_respuesta_popup" value={respuesta.respuesta}>
                                                            <img  value={respuesta.respuesta} onClick={()=>{setAprobado(false); setNoAprobado(true)}} src={no_aprobado ? img_noaprobado_hover : img_noaprobado} class="img_votar"/>
                                                    </Radio>
                                                        </>
                                                    );
                                                }
                                            });
                                            return (
                                                <>
                                            <Radio.Group style={{display:'inlineFlex'}} onChange={elegirRespuesta}>
                                                    {respuestas}
                                            </Radio.Group>
                                            <br />
                                            {no_aprobado === true &&
                                            <>
                                            <Row>
                                                <Col span={22} offset={1}>
                                                <br />
                                            <span style={{fontSize:'18px'}}>Comparte tu opinión con nosotros: </span>{userInput}
                                            <br />
                                                </Col>
                                            </Row>
                                            </>
                                            }
                                            </>
                                            );
                                        })
                                    }
                                    <br />
                                        <Row>
                                            <Col xs={{span:12,offset:6}} md={{span:8, offset:8}}>
                                            <p style={{ textAlign: 'center' }}>
                                        <span onClick={() => { enviarRespuestaPopUp(); handleClick() }}>
                                            <img src={img_votar} style={{cursor:'pointer',width:'100%'}} />
                                        </span>
                                    </p>
                                                </Col>
                                            </Row>
                                    
                                </>}
                                <br /><br />
                                <Row align="middle">
                                    {enviada &&
                                    <Col xs={14} md={24}>
                                    <p className="respuesta_enviada"><CheckOutlined /> Su respuesta fue enviada</p>
                                </Col>}
                                <Col xs={0} md={0}>
                                    <p className="respuesta_enviada"><CheckOutlined /> Su respuesta fue enviada</p>
                                </Col>
                                </Row>
                                <Row align="middle">
                                    <Col xs={6} md={4}>
                                        <img src={qr_informe} style={{width:'80%'}}/>
                                    </Col>
                                    <Col xs={18} lg={20}>
                                        <p className="qr_texto">ESCANEA EL CÓDIGO QR PARA VER EL<br />
                                            <strong>INFORME DE LABORES 2021 O <a href="https://www.antp.org.mx/asamblea/informe2021" target="_blank" style={{color:'#227dec'}}>DA CLIC AQUÍ</a></strong>
                                        </p>
                                    </Col>
                                </Row>
                                <br /><br />
                        </Col>
                    </Row>
                </div>
                <FooterIngenieriaVehicular />
            </>
        </>
    );

}

export default EventoEnVivo;