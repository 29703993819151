import React, { useState } from 'react';
import { Row, Col, Carousel, Button, Typography, Input, Radio } from 'antd';
import '../../assets/css/sala.css';

import img_aprobado from '../../assets/images/asamblea2022/antp_BotonAprobado.png';
import img_aprobado_hover from '../../assets/images/asamblea2022/antp_BotonAprobado_scroll.png';
import img_noaprobado from '../../assets/images/asamblea2022/antp_BotonNOAprobado.png';
import img_noaprobado_hover from '../../assets/images/asamblea2022/antp_BotonNOAprobado_scroll.png';
import img_votar from '../../assets/images/asamblea2022/antp_BotonVOTAR.png';

const { Text } = Typography;

let it_pregunta = 1;
let usuario_respuestas = {};

export const Preguntas = (props) => {

    const [respuesta_elegida, setRespuestaElegida] = useState(false);
    const { preguntas, onResponse } = props;
    const refCarouselPreguntas = React.createRef();
    const [aprobado,setAprobado] = useState(false);
    const [no_aprobado,setNoAprobado] = useState(false);



    const onChangeRespuesta = (value) => {
        const posibles_respuestas = Object.values(preguntas.filter((pregunta)=>(pregunta.num_pregunta === it_pregunta))[0].respuestas).filter(respuesta => respuesta.respuesta === value.target.value);
        if(posibles_respuestas.length > 0){
            usuario_respuestas['pregunta' + it_pregunta] = value.target.value;
        }else{
            usuario_respuestas['pregunta' + it_pregunta] = Object.values(preguntas.filter((pregunta)=>(pregunta.num_pregunta === it_pregunta))[0].respuestas)[0].respuesta;
        }
        setRespuestaElegida(true);
    }

    const collectionToArr = (collection) => {
        var rows = [];
        if (collection && collection.val()) {

            collection.forEach((obj) => {
                let item = obj.val();
                item.key = obj.key;
                rows.push(item);
            });
        }
        return rows;
    }

    const siguientePregunta = () => {
        setRespuestaElegida(false);
        ++it_pregunta;
        if (it_pregunta > preguntas.length) {
            onResponse(usuario_respuestas);
        } else {
            refCarouselPreguntas.current.next();
        }

    }

    let divs_preguntas = preguntas.map(pregunta => {

        const respuestas = Object.values(pregunta.respuestas).map(respuesta => {
            if(respuesta.respuesta === 'Aprobado'){
                return (
                    <>
                    
                    <Radio name="resp" className="texto_respuesta_popup" value={respuesta.respuesta}>
                        <img  onClick={()=>{setAprobado(true); setNoAprobado(false);onChangeRespuesta()}} src={aprobado ? img_aprobado_hover : img_aprobado} class="img_votar"/>
                </Radio>
                    </>
                );
            }else{
                return (
                    <>
                <Radio name="resp" className="texto_respuesta_popup" value={respuesta.respuesta}>
                        <img  value={respuesta.respuesta} onClick={()=>{setAprobado(false); setNoAprobado(true);onChangeRespuesta()}} src={no_aprobado ? img_noaprobado_hover : img_noaprobado} class="img_votar"/>
                </Radio>
                    </>
                );
            }
            {/*return (
                <Row align="middle" style={{ textAlign: 'center', marginTop: '15px' }}>

                    <Col span={1} style={{ textAlign: 'right' }}>

                        <Input name="resp" type="radio" value={respuesta.respuesta} onClick={onChangeRespuesta} style={{border:'0px solid white !important'}} />
                    </Col>
                    <Col span={22} offset={0} style={{ textAlign: 'left', paddingLeft:'5px' }}>

                        <Text>{respuesta.respuesta}</Text>
                    </Col>
                </Row>



            );*/}
        });

        return (
            <div>
                <Row style={{border:'1px solid lightgray', background: 'white'}}>
                    <Col span={24} style={{ backgroundColor: '#32abdf', paddingTop:'10px', paddingBottom:'10px' }} >
                        <Text  strong style={{ color: '#ffffff', fontSize: '16px' }}>{pregunta.pregunta}</Text>
                    </Col>
                    <Col span={22} offset={1}>

                        {respuestas}


                    </Col>
                    <Col span={24} style={{  textAlign:'right' }}>
                    <button style={{ backgroundColor: '#32abdf', color: '#000000', fontSize:'22px', borderRadius:'8px' }} onClick={siguientePregunta} disabled={!respuesta_elegida} ><strong>VOTAR</strong></button>
                    <br />
                    </Col>




                </Row>


            </div>
        );


    });

    return (
        <Carousel dots={false} ref={refCarouselPreguntas} effect="fade">
            {divs_preguntas}
        </Carousel>
    );

}
