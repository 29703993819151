import React from 'react';

import {Switch, Route} from 'react-router-dom';
import EventoInicio from '../evento/EventoInicio';
import Cuestionario from '../paginas/Cuestionario';
import Diploma from '../paginas/Diploma';

import RecuperarContrasena from '../sesion/RecuperarContrasena';
import Error from "../auth/Error";


import EventoEnvivo from '../en-vivo/EventoEnVivo';
import Admin from '../admin/Admin';
import PreguntasEnVivo from '../admin/PreguntasEnVivo';

import CerrarSesion from '../sesion/CerrarSesion';
import EstadisticaGrafica from '../conferencias/EstadisticaGrafica';

import { AuthContext } from '../../utils/AuthContext';
import PantallaTransmision from '../evento/PantallaTransmision';


const secciones = [

  
];

const secciones_auth = [
  {componente:PantallaTransmision, url:'/'},
  //{componente:EstadisticaGrafica, url:'/estadisticas'},
  //{componente:EventoInicio, url:'/'},
  //{componente:EventoInicio, url:'/inicio'},
  //{componente:Cuestionario, url:'/cuestionario'},
  //{componente:Diploma, url:'/constancia'},
  //{componente:RecuperarContrasena, url:'/recuperar'},
  //{componente:EventoEnvivo, url:'/en-vivo'},
  //{componente:CerrarSesion, url:'/cerrar-sesion'},

  //{componente:Admin, url:'/admin-preguntas-live'},
  //{componente:PreguntasEnVivo, url:'/preguntas-en-vivo'},
  //{componente:EstadisticaGrafica, url:'/encuesta-envivo'},
  {componente:Error, utl:'/*'}
  
];


export const Master = () => {

    let visualizar_secciones = secciones.map((seccion)=>{
      return(
        <Route path={seccion.url} exact component={seccion.componente} />
      );
    });

    let visualizar_secciones_auth = secciones_auth.map((seccion)=>{
      return(
        <Route path={seccion.url} exact component={seccion.componente} />
      );
    });

  return(
    <>
    <AuthContext >
    <Switch>
      {visualizar_secciones_auth}
      </Switch>
    </AuthContext>
    </>
  );
}