import React from 'react';
import Firebase from '../../utils/firebaseConfig';
import { Tabs , Row, Col, message, Select} from 'antd';
import '../../assets/css/style_demo.css';
import HeaderIngenieriaVehicular from '../master/header/HeaderIngenieriaVehicular';
import FooterIngenieriaVehicular from '../master/footer/FooterIngenieriaVehicular';

const nombre_evento = '2022';
const {Option} = Select;
const app = new Firebase();

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}
class VisualizarPreguntas extends React.Component {

    state = {
        questions: []
      }

      componentDidMount(){

        app.obtener_objetos(nombre_evento+`/configuracion`,false,(data)=>{
          app.obtener_objetos(nombre_evento+`/preguntas-publico${data[0].dia}`,true,(items) => { 
         // app.obtener_objetos(nombre_evento+`/preguntas-publico2`,true,(items) => {
            this.setState({
                questions: items,
              })
        });
    
        });

        
      }

      render(){

        return(
          <>
          <HeaderIngenieriaVehicular />
          <br /><br />
            <Row style={{backgroundColor:'white'}}>
                <Col span={24} style={{textAlign:'center'}}>
                    <p style={{fontSize:'30px', color:'black', fontWeight:'bold'}}>PREGUNTAS</p>
                </Col>
                <Col span={20} offset={2}>

                
        {this.state.questions.filter(item => {
            if(item.status === 'aceptada'){
                return true;
            }else{
                return false;
            }
        }).map((item,idx)=>{
          return  item.status === 'aceptada' ? (
          <div key={item.key}>
            <Row align="bottom">
              <Col span={16} style={{textAlign:'left'}}>
            <p style={{color:'black', lineHeight:'1.3', fontSize:'1.2em'}}>
              <span style={{fontFamily:'MyriadProSemB', fontStyle:'italic'}}>{item.nombre}</span> <br />
              <span style={{fontFamily:'MyriadProLight', fontStyle:'italic'}}>{item.organizacion}</span><br/>
              <strong>
                <span style={{fontFamily:'MyriadProLight', fontStyle:'italic'}}>A: {item.ponente}</span>
              </strong>
              <br/>
              <span style={{fontFamily:'MyriadProBold'}}>{item.pregunta}</span></p>
            </Col>
            <Col span={24}>
                <hr style={{ border:'1px solid hite'}} />
                </Col>
            </Row>
          </div>) : null;
        })}
        <br /><br />
      </Col>
            </Row>
            <br /><br />

            <FooterIngenieriaVehicular />
            </>
        );
        }

}

export default VisualizarPreguntas;