import React, {useContext, useState, useEffect} from 'react';
import {Link, useLocation } from 'react-router-dom';
import { Row, Col, Modal, notification } from 'antd';
import '../../../assets/css/ingenieria/evento.css';

import {Auth} from '../../../utils/AuthContext';
import Login from '../../registro/Login';
import LoginLibre from '../../registro/LoginLibre';
import { MenuOutlined } from '@ant-design/icons';

import logo from '../../../../src/assets/images/asamblea2022/usuariosBlanco.png';
import ima from '../../../../src/assets/images/asamblea2022/antp_BotonIniciarAyuda.png';
import registro from '../../../../src/assets/images/asamblea2022/antp_BotonIniciarSecion.png';

const secciones = [
  {nombre:'Registro', url:'https://www.antp.org.mx/asamblea-2022/registro'},
  //{nombre:'Registro', url:'/registro/carta-porte'},
];
const success = () => {
  mensaje('success','Bienvenido','');
};
const mensaje = (tipo,titulo, texto) =>{

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}
const HeaderIngenieriaVehicular = ({history}) => {

  let location = useLocation();
  console.log('la url');
  console.log(location.pathname);

  const { usuario } = useContext(Auth);
  const [en_vivo, setEnVivo] = useState(false);
  const [encuesta, setEncuesta] = useState(false);
  const [modal_login, setModalLogin] = useState(false);
  const [mostrar_menu, setMotrarMenu] = useState(false) ;

  const direccionar = (encuesta) => {
    console.log(encuesta);
    setModalLogin(false);
    //window.location.href="/en-vivo";
    //comentado
    if(encuesta !== undefined){
      if(encuesta === 0){
        setEncuesta(true);
      }else{
        setEnVivo(true);
      }
    }//
    
  }

    let visualizar_secciones = secciones.map((seccion)=>{
      return(
        <a className="titles" href={seccion.url}  target="_blank">
          <span className="seccion" >
            {seccion.nombre}
          </span>
        </a>
      );
    });

    let visualizar_secciones_movil = secciones.map((seccion)=>{
      return(
        <Col span={24} className="contenedor_link_movil">
          <a className={location.pathname === seccion.url ? "titles_subrayado" : "titles"} href={seccion.url} target="_blank">
          <span className="seccion" >
            {seccion.nombre}
          </span>
        </a>
        </Col>
      );
    });


    useEffect(()=>{
      if(location.pathname === '/sds' ){
        setModalLogin(false);
      }else if(!usuario){
        setModalLogin(true);
      }
      /*if(!usuario){
        setModalLogin(true);
      }*/
    },[]);

  return(
    <Row align="middle" style={{backgroundColor:'#033066'}}>
      <Col xs={{span:9, offset:0}} md={{span:4, offset:2}} style={{ padding: '15px' }}>
        <a href="https://api.whatsapp.com/send?phone=525557400333&text=No%20puedo%20ingresar%20a%20la%20Asamblea%20de%20ANTP."><img alt="logo" style={{width:'100%'}} src={ima}/></a>
      </Col>
      <Col xs={{span:9, offset:0}} md={{span:4, offset:4}} style={{ padding: '15px' }}>
        <img alt="logo" style={{width:'100%'}} src={logo}/>
      </Col>

      <Col xs={{span:0, offset:0}} md={{span:8, offset:0}} style={{textAlign:'right', padding:'15px'}}>
      {visualizar_secciones}
      {usuario === null ?
      (
        <>
        </>
      )
      :
      (
        <>
        <Link to="/en-vivo" className="titles" style={{display:'non'}}>
        <span className="seccion" >
            En vivo
          </span>
       </Link>
        <Link to="/cerrar-sesion" className="titles" style={{display:'non'}}>
        Cerrar sesión
      </Link>
      </>
      )
      }
      </Col>
      <Col xs={{span:4, offset:2}} md={{span:0, offset:0}} style={{ padding: '15px', textAlign:'right' }}>
        <MenuOutlined style={{fontSize:'25px', fontWeight:'bold', color:'#ffffff'}} onClick={() => setMotrarMenu(!mostrar_menu)} />
      </Col>
      {mostrar_menu &&
        <Col xs={{span:24, offset:0}} md={{span:0, offset:0}} style={{display:'non'}} className="contenedor_menu_col">
          <Row className="contenedor_menu_row">
            {visualizar_secciones_movil}
            <Col span={24} className="contenedor_link_movil">
            {usuario === null ?
                (
                  
                  <Link className="titles" to="/en-vivo">
                    <span className="seccion" >En vivo</span>
                  </Link>
                )
                :
                (
                  <>
                    <Link to="/cerrar-sesion" className="titles" style={{display:'non'}}>
                    Cerrar sesión </Link>
                    <br />
                  </>
                )
        }
        </Col>
          </Row>
        </Col>
      }
      
{/*<Row style={{}}>
           
            <Col xs={{span:12, offset:0}} md={{span:14, offset:0}} style={{display:'none'}}>
              <p style={{color:'#112f6d'}}>Inicio</p>
            </Col>
            <Col xs={{span:12, offset:0}} md={{span:10, offset:0}} style={{display:'none'}}>
              <img src={img_entrar_cin_login} style={{width:'100%', cursor:'pointer'}} onClick={() => {setModalLogin(false)}} />
            </Col>
    </Row>
      <Modal
      title={null}
          visible={modal_login}
          onOk={() => setModalLogin(false)}
          onCancel={() => setModalLogin(false)}
          closable={false}
          footer={null}
          style={{}}
          bodyStyle={{}}
         
        >
          <Login onResponse={(encuesta) => { direccionar(encuesta);}} />
        </Modal>*/}

    </Row>
  );
}

export default HeaderIngenieriaVehicular;