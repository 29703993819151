import React, { useState, useEffect, useContext }  from 'react';
import { Row, Col, Spin } from 'antd';

import { Auth } from "../../utils/AuthContext";
import { EstadisticasDivididas as Estadisticas } from './EstadisticasDivididas';
import Firebase from '../../utils/firebaseConfig';

const nombre_evento = '2022';
const app = new Firebase();

const EstadisticaGrafica = ({history}) => {

    const [etapa, setEtapa] = useState(0);
    const { usuario, datos } = useContext(Auth);
    const [cargando, setCargando] = useState(false);
    const [preguntas, setPreguntas] = useState([]);
    const [estadisticas, setEstadisticas] = useState([]);

    useEffect(() => {
        
        if (usuario===null) {
            //history.push("/login");
        }else{
            if(datos.correo){
                if(Object.entries(datos).length !== 0){
                    app.obtener_objeto(nombre_evento+'/preguntas-popup-publico', 'correo', datos.correo, siguienteEtapa);
                }
            }
        }
        
    }, [history, usuario, datos]);

    const siguienteEtapa = (usuario_respuestas) => {
        switch (etapa) {
            case 0:
                let existe_respuestas = usuario_respuestas.respuestas;
                    app.obtener_objetos(nombre_evento+'/preguntas-popup', false, (datos) => {
                        
                        setPreguntas(datos);
                        app.obtener_objetos(nombre_evento + '/preguntas-popup-publico', true, (datos) => {

                            setEstadisticas(datos);
                            setCargando(false);
                            if (typeof existe_respuestas !== 'undefined') {
                                setEtapa(2);
                            } else {
                                setEtapa(1);
                            }
                        });
                    })
                
                break;
            case 1:

                app.obtener_objetos(nombre_evento + '/preguntas-popup-publico', true, (datos) => {

                    setEstadisticas(datos);
                    setCargando(false);
                    setEtapa(2);
                });
                break;
            default:
            break;
        }
    }

    return(
        <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} lg={{ span: 24, offset: 0 }}>
            <br /><br /><br />
            <Estadisticas estadisticas={estadisticas} preguntas={preguntas} />
            <br /><br /><br />
        </Col>
    </Row>
    );
}

export default EstadisticaGrafica;