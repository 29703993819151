import React, { useState } from 'react';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import { Row, Col, Typography, Select, Card } from 'antd';
import { MinusOutlined } from '@ant-design/icons';

const { Text, Paragraph } = Typography;
const { Option } = Select;

const colors = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#F369E5',
    '#2BCB18',
    '#F80606',
    '#5203E7',
    '#03E4E7',
    '#E3AE16',
    '#161FE3'
];
let grafica_elegida = 0;
export const EstadisticasDivididas = (props) => {

    const [seleccion_estadistica, setSeleccionEstadistica] = useState(grafica_elegida);

    const { estadisticas, preguntas } = props;

    let labels = [];
    let votos = [];
    let show_preguntas = [];
    let data = [];

    let it = 0;

    //gráficas
    let divs_graficas = preguntas.map(pregunta => {

        let votos_usuario_respuesta = [];
        let usuarios_respuestas = [];
        let usuario_respuesta = '';
        let num_resp = 0;
        let label_votos = [];
        for (let i = 0; i < estadisticas.length; i++) {
            
            //if(estadisticas[i].respondio){

                if (usuario_respuesta === '') {

                    console.log(estadisticas[i]['respuesta'+pregunta.num_pregunta]);
                    //usuario_respuesta = estadisticas[i].respuestas['pregunta' + pregunta.num_pregunta];
                    //usuarios_respuestas[num_resp] = estadisticas[i].respuestas['pregunta' + pregunta.num_pregunta];
                    usuario_respuesta = estadisticas[i]['respuesta'+pregunta.num_pregunta];
                    usuarios_respuestas[num_resp] = estadisticas[i]['respuesta'+pregunta.num_pregunta];
                    votos_usuario_respuesta[num_resp] = 0;
                    label_votos.push('Votos');
                }

                let existe_respuesta = false;
                for(let j=0; j < usuarios_respuestas.length; j++){
                    //if(usuarios_respuestas[j] === estadisticas[i].respuestas['pregunta' + pregunta.num_pregunta]){
                    if(usuarios_respuestas[j] === estadisticas[i]['respuesta'+pregunta.num_pregunta]){
                        existe_respuesta = true;
                        votos_usuario_respuesta[j] = votos_usuario_respuesta[j] + 1;
                        break;
                    }
                }
                
                if (!existe_respuesta){
                    //usuario_respuesta = estadisticas[i].respuestas['pregunta' + pregunta.num_pregunta];
                    usuario_respuesta = estadisticas[i]['respuesta'+pregunta.num_pregunta];
                    num_resp++;
                    //usuarios_respuestas[num_resp] = estadisticas[i].respuestas['pregunta' + pregunta.num_pregunta];
                    usuarios_respuestas[num_resp] = estadisticas[i]['respuesta'+pregunta.num_pregunta];
                    votos_usuario_respuesta[num_resp] = 1;
                    label_votos.push('Votos');
                }
            //}
        }


        labels[it] = usuarios_respuestas;
        votos[it] = votos_usuario_respuesta;

        data[it] = {
            labels: label_votos,
            datasets: [{
                data: votos[it],
                backgroundColor: colors,
                hoverBackgroundColor: colors
            }]
        };
        it++;

        return (
            <div></div>
        );



    });

    //select pregunta
    let div_select_pregunta = preguntas.map(pregunta => {

        show_preguntas.push(pregunta.pregunta);

        return (
            <Option value={pregunta.num_pregunta - 1}>{pregunta.pregunta}</Option>
        );

    });

    //info gráfica
    
    let divs_info_grafica = labels.map(respuestas_disponibles => {

        let it_color = 0;

        let suma_votos = [];
        let votos_respuesta = [];
        for(let i=0; i < votos.length; i++){
            suma_votos[i] = 0;
            for(let j=0; j < votos[i].length; j++){
                suma_votos[i] += votos[i][j];
                votos_respuesta.push(votos[i][j]);
            }
        }

        let it_voto_pregunta = 0;
        let info = respuestas_disponibles.map(resp =>{
            let porcentaje_voto = (votos[seleccion_estadistica][it_voto_pregunta] / suma_votos[seleccion_estadistica]) * 100;
            let info_v = votos[seleccion_estadistica][it_voto_pregunta];
            it_voto_pregunta++;
            return(
            <Paragraph>
            <Row>
                <Col span={2} style={{}}>
                <MinusOutlined style={{ color:colors[it_color],backgroundColor:colors[it_color++] }} />
                </Col>
                <Col span={19} style={{textAlign:'left', paddingLeft:'4px'}}>
                {resp}
                </Col>
                <Col span={3} style={{textAlign:'left', paddingLeft:'0px', fontSize:'12px'}}>
                    { porcentaje_voto.toFixed(2) } % {info_v}
                </Col>
            </Row>
            </Paragraph>
            );
        }
            
        );

        return (
            <Card headStyle={{backgroundColor:'#2776cc', color:'white'}} title={<Text style={{textAlign:'left', color:'white'}}>Resultados:</Text>} style={{ width: '100%', border:'1px solid lightgray' }}>
                {info}
            </Card>
        );

    });

    const legend = {
        "display": true
    }

    const pintar_todas_estadisticas = preguntas.map((pregunta,index) => {
        return(
            <Row align="middle" style={{paddingBottom:'25px', backgroundColor: 'rgb(250,250,250)', paddingBottom:'250px'}}>
            <Col span={22} offset={1}>
                
            <p style={{ width:'100%', border:'0px solid #000000', color:'#000000', fontSize:'30px' }}>{pregunta.pregunta}</p>
                <br /><br />
            </Col>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 1 }} style={{display:'none'}}>
                {divs_info_grafica[index]}
            </Col>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 10, offset: 0 }}>
                <br />
                <Bar data={data[index]} legend={legend} style={{width:'100%'}} />
            </Col>
            </Row>
        );
    });

    return (
        <>
        <Row align="middle" style={{paddingBottom:'25px', backgroundColor: 'rgb(250,250,250)', display:'non'}}>
            <Col span={24}>
                <Select defaultValue={seleccion_estadistica} style={{ width:'100%', border:'2px solid #000000', color:'#000000' }} onChange={(val) => { grafica_elegida = val; setSeleccionEstadistica(val); }}>
                    {div_select_pregunta} :u
                </Select>
                <br /><br />
            </Col>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 1 }}>
                {divs_info_grafica[seleccion_estadistica]}
            </Col>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 10, offset: 0 }}>
                <br />
                <Bar data={data[seleccion_estadistica]} legend={legend} style={{width:'100%'}} />
            </Col>
            <Col>{ divs_graficas }</Col>
        </Row>
        {  /*pintar_todas_estadisticas */ }
</>

    );

}