import React, {useParams, useContext, useRef, useState, useEffect} from 'react';
import {} from 'react-router-dom';
import { Row, Col, Form, Input, Button, notification, Modal, Tabs } from 'antd';

import { Auth } from "../../utils/AuthContext";
import Firebase from '../../utils/firebaseConfig';

import '../../assets/css/sala.css';
import imaLive from '../../assets/images/04_sala_de_conferencias/01-imagen-sala-1.jpg';
import avatar from '../../assets/images/04_sala_de_conferencias/02-Foto-grande.jpg';
import { Cuestionario } from './Cuestionario';

import rede_facebook from '../../assets/images/eventos/01-ingenierIa-vehicular/iconos-redes/facebook.png';
import rede_twitter from '../../assets/images/eventos/01-ingenierIa-vehicular/iconos-redes/instagram.png';
import rede_youtube from '../../assets/images/eventos/01-ingenierIa-vehicular/iconos-redes/youtube.png';
import banner_proximamnete from '../../assets/images/asamblea2022/antp_registroasamblea_previa.jpg';
import importante_sesion from '../../assets/images/eventos/energia_del_futuro/ImportantePreguntas.png';
import gif_tapar from '../../assets/images/2antp_LlenadoCP_Cintillo.jpg';

import img_logo_antp_movil from '../../assets/images/01_impacDemo_home/antp_ReformasPenales_ponentesregistroWEB.jpg';

const { TextArea } = Input;
const { TabPane } = Tabs;

const app = new Firebase();

const mensaje = (tipo,titulo, texto) =>{

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}
const ver_transmision = [
  {img:rede_youtube, url:''},
  {img:rede_youtube, url:''}
]
const layout = {
  wrapperCol: {
    span: 24,
  },
};

const Live = (props) =>{

  const [mis_preguntas, misPreguntas] = useState(false);
  const [en_vivo, enVivo] = useState(true);
  const [preguntas_contestadas, preguntasContestadas] = useState(false);
  const [num_pregunta, setNumPregunta] = useState(0);
  const [modal_cuestionario, setModalCuestionario] = useState(false);
  const {url, comentarios, webinar, urlfb, urltw, urlyt, respuestas, dia, ver_video } = props;
  const [preguntas_popup_usuario, setPreguntasPopUpUsuario] = useState({});
  const { usuario, datos } = useContext(Auth);

  const ref_form_pregunta = useRef();
 
  console.log('dia en live', dia);
  if(usuario){
    /*app.obtener_objeto(webinar+`/respuestas-usuarios-en-vivo${dia}`,'correo',datos.correo, (data) => {
    //setPreguntasPopUpUsuario(data);
  });*/
  }else{
    //window.location.href="/";
  }

  useEffect(() => {

    if( Object.keys(preguntas_popup_usuario).length !== 0){

      app.obtener_objetos(webinar+'/configuracion', true, (data) => {
        
        setNumPregunta(data[0].pregunta_popup);
  
        if(data[0].pregunta_popup === 0){
          setModalCuestionario(false);
        }else{
            if(preguntas_popup_usuario['respuesta'+data[0].pregunta_popup] === undefined || preguntas_popup_usuario['respuesta'+data[0].pregunta_popup] === null){
              setModalCuestionario(true);
            }else{
              setModalCuestionario(false);
            }
        }
      });

    }

  },[preguntas_popup_usuario]);

  const onResponseCuestionario = (valor_respuesta) => {
    setModalCuestionario(false);

    //preguntas_popup_usuario['correo'] = usuario.correo;
    preguntas_popup_usuario['respuesta'+num_pregunta] = valor_respuesta;
    
    app.escribir(webinar+`/respuestas-usuarios-en-vivo${dia}`,preguntas_popup_usuario.key,preguntas_popup_usuario, (item) => {
      mensaje('success','Gracias por tu participación','');
    });

  }

  
    let visualizar_preguntas = comentarios.filter((pre) => {
        if(pre.status === 'aceptada' && pre.ver_video == ver_video){
          return true;
        }else{
          return false;
        }
    }).map((pre)=>{

      let answers = respuestas.filter(answer => answer.key_pregunta === pre.key).map(answer => {
        return(
          <div className="balloon_respuesta">
          <p className="texto-respuetas">
          <span className="text-pre">{answer.respuesta}</span>
            </p>
        </div>
        );
      });

      return(
        <>
        <div className="balloon">
          <p className="texto-preguntas">
          <span className="text-pre">{pre.pregunta}</span><br /><br />
            <strong><span className="text-nom">{pre.nombre}</span></strong><br />
            <strong><span className="text-empre">{pre.organizacion}</span></strong>
            </p>
        </div>
        {answers}
        </>
      );
    });

    let ver_mispreguntas = comentarios.filter((pre) => {
      if(datos){
        if(pre.correo === datos.correo && pre.ver_video === ver_video){
          return true;
        }else{
          return false;
        }
      }

      return false;
  }).map((seccion)=>{

    let answers = respuestas.filter(answer => answer.key_pregunta === seccion.key).map(answer => {
      return(
        <div className="balloon_respuesta">
          <p className="texto-respuetas">
          <span className="text-pre">{answer.respuesta}</span>
            </p>
        </div>
      );
    });

      return(
        <>
        <div className="balloon">
        <p className="texto-preguntas">
        <span className="text-pre">{seccion.pregunta}</span><br /><br />
          <strong><span className="text-nom">{seccion.status}</span></strong>
          </p>
      </div>
      {answers}
      </>
      );
    });

    let ver_contestadas = comentarios.filter((pre) => {
      if(pre.status === 'contestada' && pre.ver_video == ver_video){
        return true;
      }else{
        return false;
      }
  }).map((pre)=>{

    let answers = respuestas.filter(answer => answer.key_pregunta === pre.key).map(answer => {
      return(
        <>
        <div className="balloon_respuesta">
          <p className="texto-respuetas">
          <span className="text-pre">{answer.respuesta}</span>
            </p>
        </div>
        
        </>
      );
    });

      return(
        <>
        <div className="balloon" >
        <p className="texto-preguntas">
          <span className="text-pre">{pre.pregunta}</span><br /><br />
            <strong><span className="text-nom">{pre.nombre}</span></strong><br />
            <strong><span className="text-empre">{pre.organizacion}</span></strong>
            </p>
      </div>
      {answers}</>
      );
    });

  const onFinish = values => {

    let pregunta = {
      empresa:datos.empresa ? datos.empresa : datos.procedencia,
      organizacion: datos.empresa ? datos.empresa : datos.procedencia,
      nombre: datos.nombre,
      ponente: (values.ponente === '' || values.ponente === ' ') ? '...' : values.ponente,
      pregunta: values.pregunta,
      correo: datos.correo,
      ver_video: parseInt(ver_video),
      status: 'pendiente'
    }

    app.escribir(webinar+`/preguntas-publico${dia}`,undefined, pregunta, () => {
      mensaje('success','Tu pregunta ha sido enviada');
      ref_form_pregunta.current.resetFields();
    });
  };

  
  
  return(
    
   <Row justify="" align="middle" >
     
      <Col xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:20, offset:2}} lg={{span:url !== null ? 20 : 20, offset:2}} className="">{/**14 : 20, */}
      <p className="titulo_webinar_2"><br />
      {/*<strong style={{color:'#8ec549'}}>Taller de llenado paso a paso del CFDI con complemento carta porte</strong>*/}
      </p>
      <Row >
        <Col span={20} offset={2}>
        {url !== null ?
      (<>
        <div className="contenedor_video">
            <iframe className="video" src={`${url}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>
          </div>
      </>)
      :
      (
        <>
        <div >
        <img src={banner_proximamnete} width="100%" />
        </div>
        </>
      )  
      }
      <p class="clic_ver">No olvides verificar que tu red te permita reproducir contenido de YouTube. Si no puedes visualizar el video da clic <a target="_blank" href="https://api.whatsapp.com/send?phone=525557400333&text=No%20puedo%20visualizar%20el%20webinar%20de%20ANTP.">aquí.</a> </p>
        </Col>
        
      </Row>
      
       
    
    </Col>
    { url !== null ?
      (<>
        <Col xs={{span:20, offset:2}} lg={0} style={{display:'none'}}>
          <br /><br />
          <p class="clic_ver" style={{display:'none'}}>Si no puedes visualizar el video, también puedes visualizarlo en 
            <a href={urlyt} target="_blank"> Youtube </a>
            { urlfb !== null ?
              (<>
                <a href={urlfb} target="_blank"> Facebook </a>
              </>)
              :
              (
                <> 
                </>
              )  
            }
            { urltw !== null ?
              (<>
                <a href={urltw} target="_blank"> Linkedin </a>
              </>)
              :
              (
                <> 
                </>
              )  
            }
          </p>
        </Col>
         </>)
         :
         (
           <> 
           </>
         )  
       }
    <Col xs={ url !== null ? 0 : 0} lg={0}>{/*cambiar a 24:0 */}
      <br /><br /><br />
    </Col>
    <Col xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:20, offset:2}} lg={{span:6, offset:0}} className="contenedor_live_preguntas" style={{display: url !== null ? 'none' : 'none'}}>{/** '':'none */}
      <Row justify="">
        <Col span={0} >
          <img alt="live" src={avatar} style={{ width:'100%' }} />
        </Col>
        <Col xs={{span:24, offset:0}} md={{span:24, offset:0}} lg={{span:23, offset:1}}  className="header-preguntas">
          <p className="pregunta-p"><strong>PREGUNTA AL PONENTE</strong></p>
        </Col>
        <Col xs={0} md={{span:24, offset:0}} lg={{span:23, offset:1}}>
        <Row>
            <Col span={8} className={mis_preguntas === true ? 'tipo_preguntas_select' : 'tipo_preguntas'}>
              <p style={{marginBottom:'0px'}} onClick={() => (misPreguntas(true), enVivo(false), preguntasContestadas(false))}>Mis preguntas</p>
            </Col>
            <Col span={8} className={en_vivo === true ? 'tipo_preguntas_select' : 'tipo_preguntas'}>
              <p style={{marginBottom:'0px'}} onClick={() => (enVivo(true), misPreguntas(false), preguntasContestadas(false))}>En vivo</p>
            </Col>
            <Col span={8} className={preguntas_contestadas === true ? 'tipo_preguntas_select' : 'tipo_preguntas'}>
              <p style={{marginBottom:'0px'}} onClick={() => (enVivo(false), misPreguntas(false), preguntasContestadas(true))}>Contestadas</p>
            </Col>
            </Row>
        </Col>
        <Col xs={0}  md={{span:24, offset:0}} lg={{span:23, offset:1}} className="ver-preguntas" >
          <Row>
            <Col span={24}>
            {mis_preguntas && (
              <div>
                {ver_mispreguntas}
                </div>
            )}
            {en_vivo && (
              <div>
              {visualizar_preguntas}</div>
            )}
            {preguntas_contestadas && (
              <div>
              {ver_contestadas}</div>
            )}
            </Col>
          </Row>
        </Col>
        <Col xs={{span:24, offset:0}} md={0}  >
        <Row>
          <Col span={24} className="tipo_preguntas_select">
              <p style={{marginBottom:'0px'}}>En vivo</p>
            </Col>
            <Col span={24} className="ver-preguntas">
            
              <div>
              {visualizar_preguntas}</div>
           
            </Col>
        </Row>
        </Col>
        
        <Col xs={{span:24, offset:0}}  md={{span:24, offset:0}} lg={{span:23, offset:1}} className="contenedor_enviar">


        <Row>
              {usuario ? 
              <Col span={22} offset={1} >

                <Form
                {...layout}
                name="basic"
                initialValues={{
                  pregunta:'',
                  remember: true,
                }}
                  onFinish={onFinish}
                  ref={ref_form_pregunta}
                >

<Form.Item
                
                name="ponente"
                rules={[
                  {
                    required: false,
                    message: 'Por favor escribe a que ponente va dirigida tu pregunta',
                  },
                ]}
              >
                <Input className="text_enviar" placeholder={'¿A quien va dirigida tu pregunta?'} disabled={false} />{/*disabled={(usuario) ? false : true} */}
              </Form.Item>
                <Form.Item
                
                  name="pregunta"
                  rules={[
                    {
                      required: true,
                      message: 
                      <>Por favor escribe una pregunta</>
                      
                    },
                  ]}
                >
                  <TextArea className="text_enviar" disabled={false} />{/*disabled={usuario ? false : true} */}
                </Form.Item>
                <Row>

                <Col span={5} offset={1} style={{textAlign:'', paddingTop:'5px', zIndex:99}}>
                <Button htmlType="submit" className="btn-enviar">
                      Enviar
                  </Button>
              </Col>
              
              <Col span={17} style={{ padding:'5px 0px 0px 10px'}}>
                <p className="sujeto">
                      <>* Todas las preguntas están sujetas a aprobación</>
                      
                    
                    </p>
              </Col>
              <Col>
              <br /><br /></Col>

                </Row>
              

</Form></Col>
              
              :
              <Col span={22} offset={1}>
                
                <img src={ importante_sesion } className="img_preguntas_sin_sesion" onClick={()=> {}} />
              </Col>
            }

            <Col xs={{span:24}} md={{span:0}}>
            <br /><br />
            </Col>
              
              </Row>

      
      </Col>
      
    </Row>
    </Col>
    { url !== null ?
      (<>
        <Col xs={0} lg={{span:20, offset:2}} style={{display:'none'}}>
          <p class="clic_ver">Si no puedes visualizar el video, también puedes visualizarlo en
            <a href={urlyt} target="_blank"> Youtube </a>
            { urlfb !== null ?
              (<>
                <a href={urlfb} target="_blank"> Facebook </a>
              </>)
              :
              (
                <> 
                </>
              )  
            }
            { urltw !== null ?
              (<>
                <a href={urltw} target="_blank"> Linkedin </a>
              </>)
              :
              (
                <> 
                </>
              )  
            }
          </p>
        </Col>
         </>)
         :
         (
           <> 
           </>
         )  
       }
    <Modal
          title="Encuesta de Satisfacción"
          visible={modal_cuestionario}
          footer={null}
          onCancel={() => setModalCuestionario(false)}
        >
          
          <Cuestionario nombre_curso={webinar} correo={usuario ? usuario.email : null} onResponse={onResponseCuestionario} />
        </Modal>
   </Row>
  );
}

export default Live;