import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Carousel, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { Auth } from "../../utils/AuthContext";
import '../../assets/css/ingenieria/evento.css';
import HeaderIngenieriaVehicular from '../master/header/HeaderIngenieriaVehicular';
import FooterIngenieriaVehicular from '../master/footer/FooterIngenieriaVehicular';
import Login from '../registro/Login';

import banner from '../../assets/images/asamblea2023/bienvenidos_asamblea__.jpg';
import banner_movil from '../../assets/images/asamblea2023/bienvenidos_asamblea__movil.jpg';

const PantallaTransmision = () =>{


  return(
    <>
    
    <Row justify="center" align="middle">
      <Col xs={0} md={24}>
        <a href="https://us02web.zoom.us/j/84838237385?pwd=d3p6bk1RSjhOTHJUM3JTcDNSWncxUT09" target='blank'>
        <img src={banner} style={{width:'100%'}} />
        </a>
      </Col>
      <Col xs={24} md={0}>
      <a href="https://us02web.zoom.us/j/84838237385?pwd=d3p6bk1RSjhOTHJUM3JTcDNSWncxUT09" target='blank'>
      <img src={banner_movil} style={{width:'100%'}} />
      </a>
      </Col>
      
    </Row>
    <FooterIngenieriaVehicular />


    </>
  );
}

export default PantallaTransmision;