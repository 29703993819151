import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Form, Input, Button, notification } from "antd";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import Firebase from "../../utils/firebaseConfig";
import { Auth } from "../../utils/AuthContext";
import Errores from './Errores'
import '../../assets/css/registro.css';
import { permitir_entrada } from '../../utils/ConexionServidor';

import img_crear from '../../assets/images/eventos/energia_del_futuro/03 BotonRegistrateAqui.png';
import img_entrar from '../../assets/images/asamblea2022/antp_BotonIniciarSecion.png';
import sin_login from '../../assets/images/sitio/registro/SinLogin.png';

import RecuperarContrasena from "../sesion/RecuperarContrasena";

const app = new Firebase();

const nombre_webinar = "2022";

const mensaje = (tipo, titulo, texto) => {
    notification[tipo]({
        message: titulo,
        description: texto,
        duration: 10,
    });
}

const Login = ({ history, onResponse }) => {

    const { usuario } = useContext(Auth);
    const [error, seterror] = useState('');
    const [correo_recuperar, setCorreoRecuperar] = useState('');
    const [mostrar_recuperar, setMostrarRecuperar] = useState(false);

    useEffect(() => {


        if (usuario) {
            onResponse();
        }
    }, [history, usuario]);

    const onFinish = async values => {

        const {correo} = values;

        values.nombre_curso = nombre_webinar;

        permitir_entrada(values).then(({data,status}) => {
            switch(status){
                case 0:
                    localStorage.setItem('usuario',JSON.stringify(data));
                    app.app.auth().signInAnonymously().then(()=>{
                        const encuesta = data.encuesta;
                        app.obtener_objeto_completo(nombre_webinar+'/usuarios','correo',data.correo,JSON.parse(localStorage.getItem('usuario')),(data)=>{
                            mensaje('success', 'Bienvenido', '');
                            onResponse(encuesta);
                        });

                    }).catch((error) => {
                        mensaje('error', 'Error al cargar usuario, inténtalo más tarde', '');
                    });
                break;
                default:
                    mensaje('error', 'Advertencia',data);
                break;
            }
        });

    };

    return (
        !mostrar_recuperar ?
            (
                <Row className="contenedor_registro">

                    <Col span={16} offset={4}>
                        <Form className="login-form" onFinish={onFinish} layout="vertical"

                        >
                            <Row>
                                <Col span={24}><br />
                                <p className="iniciar_login">
                                <strong>INICIAR SESIÓN</strong>
                                </p>
                                </Col>

                            </Row>

                            <Form.Item
                                name="correo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingresa tu usuario!',
                                    },
                                ]}
                            >
                                <Input style={{ backgroundColor: '#ffffff', borderColor:'#236ee5', fontSize:'20px' }} placeholder="Correo Electrónico Registrado" />
                            </Form.Item>
                            <Form.Item
                                name="pass"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingresa tu contraseña!',
                                    },
                                ]}
                            >
                                <Input style={{ backgroundColor: '#ffffff', borderColor:'#236ee5', fontSize:'20px' }} placeholder="Apellido" />
                            </Form.Item>


                            {error ? <Form.Item><Errores mensaje={error} /></Form.Item> : null}
                            <Form.Item>
                                <Row>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <br />
                                        <button htmlType="submit" style={{ backgroundColor: 'white', border: '1px solid white', padding: '0px' }} >
                                            <img style={{ width: '200px', padding: '0px', cursor: 'pointer' }} src={img_entrar} alt="Entrar" />
                                        </button>
                                        <br /><br /><br />
                                        {/*Si tienes problemas para ingresar, da clic<a target="_blank" href="https://api.whatsapp.com/send?phone=525557400333&text=No%20puedo%20ingresar%20al%20webinar%20de%20ANTP."> aquí</a>*/}
                                        <span style={{ color: 'blue', cursor: 'pointer', display: 'none' }} onClick={() => setMostrarRecuperar(true)}>¿Olvidaste tu contraseña?</span>
                                    </Col>
                                    <Col span={24} style={{ textAlign: 'center', display: 'none' }}>
                                        <br />
                                        <span style={{ color: '#112f6d' }}><strong>¿No tienes cuenta?</strong></span>
                                        <br />
                                        <button onClick={() => { history.push("/registro/carta-porte"); }} style={{ backgroundColor: 'white', border: '1px solid white', padding: '0px' }} >
                                            <img style={{ width: '200px', cursor: 'pointer' }} src={img_crear} alt="Entrar" />
                                        </button>
                                    </Col>
                                </Row>

                            </Form.Item>

                        </Form>

                    </Col>
                </Row>
            )
            :
            (
                <RecuperarContrasena correo={correo_recuperar} onResponse={() => setMostrarRecuperar(false)} />
            )

    );
};
export default withRouter(Login);
